import { fetchApi, buildFetchContext, fetchWithoutToken } from '../util/fetch-api';


/**
 * Attempts to fetch user.
 * Sends a request to server with authentication to validate against.
 * @param {Object} [options] Options to pass to fetch()
 * @returns {Promise} Always resolves. Will either resolve with
 * js object if user session, or null if no user session.
 */
export async function fetchUser(options = {}) {
  let fetchImpl = fetchApi;
  if (options.context) {
    const { fetchWithToken } = buildFetchContext(options.context);
    fetchImpl = fetchWithToken;
  }

  try {
    const json = await fetchImpl('/user/v1/profile', options);
    return { ...json, is_admin: (json.roles && json.roles.length > 0) };
  } catch (e) {
    return null;
  }
}

/**
 * Creates a new user.
 * Sends a POST request to server with email and targeting information.
 * @param {Object} userData - The user data to be sent.
 * @param {string} userData.email - The email of the user.
 * @param {string} userData.firstName - The first name of the user. Optional.
 * @param {Object} userData.targeting - The targeting information for the user.
 * @param {Object} [options] - Options to pass to fetch()
 * @returns {Promise} Resolves with the created user data or rejects
 *                    with an error.
 */
export async function createUser(userData, options = {}) {
  try {
    const { email, firstName, lastName, targeting } = userData;
    const requestBody = { email, targeting };
    if (firstName) { requestBody.first_name = firstName }
    if (lastName) { requestBody.last_name = lastName }

    const response = await fetchWithoutToken('/user/v1/profile', {
      ...options,
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });
    const json = await response.json();
    return {
      data: { ...json },
      status: response.status
    };
  } catch (e) {
    return {
      data: null,
      status: e.response?.status || 500,
      error: `Failed to create user: ${e.message}`
    };
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withChildren } from '@builder.io/react/lite';
import useMediaQuery from '@glowforge/gf-styled/src/hooks/useMediaQuery';
import { QUERIES } from '@glowforge/gf-styled/src/theme/constants';
import YoutubeVideo from './youtube-video';
import { Background, Overlay, Video, Wrapper, ChildrenWrapper } from './hero-banner.styles';
import {
  backgroundPositions,
  backgroundRepeats,
  backgroundSizes,
  heroHeights,
} from './hero-banner.config';

export const HeroBannerV2 = ({
  attributes,
  background,
  backgroundMobile,
  backgroundPosition,
  backgroundPositionMobile,
  backgroundRepeat,
  backgroundRepeatMobile,
  backgroundSize,
  backgroundSizeMobile,
  builderState,
  heroHeightDesktop,
  heroHeightMobile,
  children,
  isVideo,
  isVideoMobile,
  showOverlay,
  textColor,
  youtube,
}) => {

  const isDesktopScreen = useMediaQuery(QUERIES.desktopAndUp);
  let videoSrc = '';

  let youtubeEmbed = youtube.desktop || youtube.mobile;

  if (!isDesktopScreen && youtube.mobile) {
    youtubeEmbed = youtube.mobile;
  }

  if (isDesktopScreen && isVideo) {
    videoSrc = background;
  }
  if (!isDesktopScreen && isVideoMobile) {
    videoSrc = backgroundMobile;
  }

  return (
    <Wrapper
      heroHeightDesktop={heroHeightDesktop}
      heroHeightMobile={heroHeightMobile}
      includeNav={builderState?.includeNav ?? true}
      textColor={textColor}
      youtubeEmbed={[youtubeEmbed, youtube.isVerticalVideo]}
      {...attributes}>
      {youtubeEmbed?.length ? (
        <YoutubeVideo url={youtubeEmbed} />
      ) : (
        <Background
          background={background}
          backgroundMobile={backgroundMobile}
          backgroundPosition={backgroundPosition}
          backgroundPositionMobile={backgroundPositionMobile}
          backgroundSize={backgroundSize}
          backgroundSizeMobile={backgroundSizeMobile}
          backgroundRepeat={backgroundRepeat}
          backgroundRepeatMobile={backgroundRepeatMobile}
          isVideo={isVideo}
          isVideoMobile={isVideoMobile}>
          {videoSrc?.length ? (
            <Video
              key={videoSrc}
              playsInline
              autoPlay
              loop='loop'
              muted
              width='100%'
              height='100%'>
              <source src={videoSrc} />
            </Video>
          ) : null}
        </Background>
      )}
      {showOverlay ? <Overlay opacity={25} /> : null}
      {youtubeEmbed?.length ? <ChildrenWrapper>{children}</ChildrenWrapper> : children}
    </Wrapper>
  );
};
HeroBannerV2.propTypes = {
  attributes: PropTypes.shape({}),
  background: PropTypes.string,
  backgroundMobile: PropTypes.string,
  backgroundPosition: PropTypes.oneOf(backgroundPositions),
  backgroundPositionMobile: PropTypes.oneOf(backgroundPositions),
  backgroundRepeat: PropTypes.oneOf(backgroundRepeats),
  backgroundRepeatMobile: PropTypes.oneOf(backgroundRepeats),
  backgroundSize: PropTypes.oneOf(backgroundSizes),
  backgroundSizeMobile: PropTypes.oneOf(backgroundSizes),
  builderState: PropTypes.shape({
    includeNav: PropTypes.bool,
  }),
  heroHeightDesktop: PropTypes.oneOf(heroHeights),
  heroHeightMobile: PropTypes.oneOf(heroHeights),
  children: PropTypes.node,
  isVideo: PropTypes.bool,
  isVideoMobile: PropTypes.bool,
  showOverlay: PropTypes.bool,
  textColor: PropTypes.string,
  youtube: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
    isVerticalVideo: PropTypes.bool
  }),
};

HeroBannerV2.defaultProps = {
  attributes: {},
  background: null,
  backgroundMobile: null,
  backgroundPosition: backgroundPositions[0],
  backgroundPositionMobile: backgroundPositions[0],
  backgroundRepeat: backgroundRepeats[0],
  backgroundRepeatMobile: backgroundRepeats[0],
  backgroundSize: backgroundSizes[0],
  backgroundSizeMobile: backgroundSizes[0],
  builderState: { includeNav: true },
  textColor: null,
  heroHeightDesktop: heroHeights[0],
  heroHeightMobile: heroHeights[0],
  children: null,
  isVideo: false,
  isVideoMobile: false,
  showOverlay: true,
  youtube: {
    desktop: '',
    mobile: '',
    isVerticalVideo: false
  }
};

export const HeroBannerWithChildren = withChildren(HeroBannerV2);
export default HeroBannerWithChildren;
